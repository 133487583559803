import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`All blog pages`}</h1>
    <p>{`June 2023 `}<a parentName="p" {...{
        "href": "/blog/industrial-web-design"
      }}>{`Building Expert Tools Using the Web Medium`}</a>{`
May 2023 `}<a parentName="p" {...{
        "href": "/blog/introduction-data-viz-web"
      }}>{`Introduction to Data Visualization for the Web`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      